<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { isPc, getQueryString } from '@/utils/index';

export default {
  name: 'app',
  created() {
    this.initParams();
    this.initHome();
  },

  methods: {
    initParams() {
      const tmpInviteCode = getQueryString('inviteCode');
      const bindId = getQueryString('bindId');
      const h5OpenId = getQueryString('h5_openId');
      if (tmpInviteCode) {
        this.$store.commit('setInviteCode', tmpInviteCode);
      }
      if (bindId) {
        this.$store.commit('setBindId', bindId);
        console.log(bindId);
      }
      if (h5OpenId) {
        this.$store.commit('setH5OpenId', h5OpenId);
        console.log(h5OpenId);
      }
    },
    initHome() {
      const tmpIsPc = isPc();
      if (!tmpIsPc) {
        console.log(location.href);
        if (location.href.indexOf('phoneRegister') > -1) {
          this.$router.replace({
            path: '/phoneRegister',
          });
        } else if (location.href.indexOf('home') > -1) {
          this.$router.replace({
            path: '/phoneHome',
          });
        } else {
          this.$router.replace({
            // path: '/phoneAnnualMeeting',
          });
        }
      }
    },
  },
};
</script>

<style lang="less">
/* 全局样式 */
* {
  margin: 0;
  padding: 0;
}
body {
  // background-color: #f4f4f4;
  background-color: rgba(255, 255, 255, 1);
  color: #000000;
  // font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
}
div {
  box-sizing: border-box;
}
img {
  max-width: 100%;

  height: auto;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
</style>
